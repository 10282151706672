export default class FiltroImportarArquivo {
  constructor(
    entidade = null,
    consignatariaId = null,
    anoReferencia = null,
    mesReferencia = null,
    tipo = null,
  ) {
    this.entidade = entidade
    this.consignatariaId = consignatariaId
    this.anoReferencia = anoReferencia
    this.mesReferencia = mesReferencia
    this.tipo = tipo
  }
}
