<template>
  <div>
    <Dialog
      :visible.sync="abrir"
      :containerStyle="{ width: '500px' }"
      :closable="true"
      :modal="true">
      <template #header>
        <i class="pi pi-check"></i>
        <h5>Confirmar Operação</h5>
      </template>
      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-12">
          <h6>{{ exibirMsg }}</h6>
          <div
            v-if="
              operacao === 'CancelarCompraDivida' ||
              operacao === 'RecusarCompraDivida'
            ">
            <div class="field col-12 md:col-12">
              <label for="observacao" class="required">Motivo</label>
              <Textarea
                id="observacao"
                v-model="v$.observacao.$model"
                :autoResize="true"
                :class="{
                  'p-invalid': submitted && v$.observacao.$invalid,
                }"
                @keyup.enter="confirmar" />
              <div
                v-if="submitted && v$.observacao.required.$invalid"
                class="p-error">
                O campo observação é obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" @click="fechar" />
        <Button
          label="Sim"
          icon="pi pi-check"
          class="p-button-success"
          @click="confirmar" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'

export default {
  props: {
    exibir: {
      type: Boolean,
      default: false,
    },
    operacao: {
      type: String,
      default: '',
    },
    msg: {
      type: String,
      default: 'Deseja realmente executar esta operação?',
    },
    metodo: {
      type: Function,
      default: null,
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      observacao: null,
      submitted: false,
    }
  },

  validations() {
    return {
      observacao: {
        required: requiredIf(
          this.operacao === 'CancelarCompraDivida' ||
            this.operacao === 'RecusarCompraDivida',
        ),
      },
    }
  },

  computed: {
    abrir: {
      get() {
        return this.exibir
      },
      set(value) {
        this.$emit('mudarVisibilidade', value)
        this.limparCampos()
      },
    },
    exibirMsg() {
      return this.msg
    },
  },

  methods: {
    confirmar() {
      if (
        this.operacao === 'CancelarCompraDivida' ||
        this.operacao === 'RecusarCompraDivida'
      ) {
        this.submitted = true
        this.v$.observacao.$touch()
        if (this.v$.observacao.$invalid) {
          return
        } else {
          this.metodo(this.observacao)
          this.limparCampos()
        }
      } else {
        this.metodo()
        this.limparCampos()
      }
    },
    limparCampos() {
      this.submitted = false
      this.v$.$reset()
      this.fechar()
    },
    fechar() {
      this.$emit('mudarVisibilidade', false)
    },
  },
}
</script>
<style scoped>
h5 {
  font-weight: bold;
  text-align: center;
}
h6 {
  font-weight: bold;
  text-align: center;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
